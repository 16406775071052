import React, { Component } from 'react';
import './CodeWars.css';
import CurrentWinner from './components/currentWinner/CurrentWinner';
import Leaderboard from './components/leaderboard/Leaderboard';
import Score from './components/score/Score';
import TimeRemaining from './components/timeRemaining/TimeRemaining';
import { connect } from 'react-redux';
import { fetchLeaderboardData } from './redux/leaderboard';
import debounce from 'lodash/debounce';
import moment from 'moment';
import 'particles.js';

const defaultStat = { score: 0 };

class CodeWars extends Component {
  state = {
    innerWidth: window.innerWidth,
  };

  componentDidMount() {
    window.particlesJS.load('particles-js', 'particlesjs-config.json');
    this.props.fetchData();
    setInterval(this.props.fetchData, 30000);
    window.addEventListener('resize', this.setSize);
  }

  setSize = debounce(() => {
    this.setState({
      innerWidth: window.innerWidth,
    });
  }, 100);

  componentWillUnmount() {
    clearInterval(this.props.fetchData);
    window.removeEventListener('resize', this.setSize);
  }

  renderPromo = () => (
    <div className='container' style={{ transform: `scale(${Math.min(this.state.innerWidth / 3840, 1)})` }}>
      <div className='teaser'>
        <div className='title'>1001 CODEWARS NIGHT</div>
        
        <div className='time'>
          Competition starts {moment(this.props.startDate).fromNow()}
        </div>

        <div className='hosted-by'>Hosted by POSSIBLE</div>
      </div>

      <div className='first-prize prize-container'>
        <div className='title'>Winner Prize:</div>
        <img src='prize.png' alt='winner prize' />
        <div className='name'>Raspberry Pi 3</div>
      </div>

      <div className='second-prize prize-container'>
        <div className='title'>Prize for 2nd place:</div>
        <img src='second_prize.png' alt='second prize' />
        <div className='name'>Bowling tickets</div>
      </div>

      <div className='third-prize prize-container'>
        <div className='title'>Prize for 3rd place:</div>
        <img src='third_prize.png' alt='thrid prize' />
        <div className='name'>Movie tickets (for two)</div>
      </div>
    </div>
  );

  renderCompetition = () => (
    <div className='container' style={{ transform: `scale(${Math.min(this.state.innerWidth / 3840, 1)})` }}>
      <CurrentWinner winner={this.props.winner} />
      <Score score={this.props.collectiveScore} />
      <Leaderboard
        noWinnerYet={(this.props.winner.statistics || defaultStat).score === 0}
        data={this.props.data}
      />
      <TimeRemaining startDate={this.props.startDate} endDate={this.props.endDate} />
    </div>
  )

  renderFinished = () => {
    return (
      <div className='container' style={{ transform: `scale(${Math.min(this.state.innerWidth / 3840, 1)})` }}>
        {this.props.winner && (
          <div className='teaser'>
            <div className='title'>WINNER</div>
    
            <img style={{ borderRadius: '50%', marginBottom: '40px', marginTop: '-40px', width: '240px' }} src={this.props.winner.image} alt='' />
            
            <div className='time'>
              {this.props.winner.name}
              <br/>
              <span style={{ fontSize: '60px' }}>
                with {(this.props.winner.statistics || defaultStat).score} points!
              </span>
            </div>
    
            <div className='hosted-by'>You won a Raspberry Pi 3!</div>
          </div>
        )}
  
        {this.props.data[0] && (
          <div className='teaser'>
            <div className='title'>2nd place</div>
    
            <img style={{ borderRadius: '50%', marginBottom: '40px', marginTop: '-40px', width: '240px' }} src={this.props.data[0].image} alt='' />
            
            <div className='time'>
              {this.props.data[0].name}
              <br/>
              <span style={{ fontSize: '60px' }}>
                with {(this.props.data[0].statistics || defaultStat).score} points!
              </span>
            </div>
    
            <div className='hosted-by'>You won Bowling tickets!</div>
          </div>
        )}
  
        {this.props.data[1] && (
          <div className='teaser'>
            <div className='title'>3rd place</div>
    
            <img style={{ borderRadius: '50%', marginBottom: '40px', marginTop: '-40px', width: '240px' }} src={this.props.data[1].image} alt='' />
            
            <div className='time'>
              {this.props.data[1].name}
              <br/>
              <span style={{ fontSize: '60px' }}>
                with {(this.props.data[1].statistics || defaultStat).score} points!
              </span>
            </div>
    
            <div className='hosted-by'>You won 2 movie tickets!</div>
          </div>
        )}
  
        <div className='teaser'>
          <div className='title'>Congrats! We scored</div>
          
          <div className='time' style={{ fontSize: '160px' }}>
            {this.props.collectiveScore} points!
          </div>
  
          <div className='hosted-by'>Thank you for participating!</div>
        </div>
      </div>
    );
  }

  renderLayout = (now, startDate, endDate) => {
    if (now > endDate) {
      return this.renderFinished()
    }

    if (now > startDate) {
      return this.renderCompetition();
    }

    return this.renderPromo();
  }

  render() {
    return (
      <>
        <div id="particles-js" />

        {this.renderLayout(Date.now(), this.props.startDate, this.props.endDate)}
      </>
    )
  }
}

const mapStateToProps = (state) => ({
  winner: state.leaderboard.winner,
  collectiveScore: state.leaderboard.collectiveScore,
  data: state.leaderboard.data,
  quiz: state.leaderboard.quiz,
  startDate: state.leaderboard.quiz.startsAt * 1000,
  endDate: state.leaderboard.quiz.endsAt * 1000
});

const mapDispatchToProps = (dispatch) => ({
  fetchData: () => dispatch(fetchLeaderboardData())
})

export default connect(mapStateToProps, mapDispatchToProps)(CodeWars);
