import { createStore, applyMiddleware, combineReducers } from 'redux';
import { createLogger } from 'redux-logger';
import thunk from 'redux-thunk';
import { createReducer } from 'redux-create-reducer';
import { leaderboard } from './leaderboard';
var firebase = require('firebase');
firebase.initializeApp({ 
  apiKey: "AIzaSyA4huYjWjcmkQyZMmEcVj2FAiIs-HGqoyI",
  authDomain: "quiz-database-13c42.firebaseapp.com",
  databaseURL: "https://quiz-database-13c42.firebaseio.com",
  projectId: "quiz-database-13c42",
  storageBucket: "quiz-database-13c42.appspot.com",
  messagingSenderId: "668161376315",
  appId: "1:668161376315:web:d00f607270720c72125c1e"
});

const AUTHENTICATE_START = 'USER/AUTHENTICATE_START';
const AUTHENTICATE_SUCCESS = 'USER/AUTHENTICATE_SUCCESS';
const AUTHENTICATE_ERROR = 'USER/AUTHENTICATE_ERROR';
const PROFILE_UPDATE = 'USER/PROFILE_UPDATE';
const UPDATE_POSITION = 'USER/UPDATE_POSITION';
const LOGOUT = 'USER/LOGOUT';

const initialState = {
  error: '',
  isLoading: false,
};

const middlewares = [thunk, createLogger({ collapsed: true })];

const user = createReducer(initialState, {
  [AUTHENTICATE_SUCCESS]: (state, { payload }) => ({
    ...state,
    ...payload,
  }),
  [PROFILE_UPDATE]: (state, { payload }) => ({
    ...state,
    ...payload,
  }),
  [UPDATE_POSITION]: (state, { payload }) => ({
    ...state,
    position: payload,
  }),
  [AUTHENTICATE_ERROR]: (state, { error }) => ({
    ...state,
    error,
  }),
  [LOGOUT]: () => ({
    ...initialState,
  }),
});

export const store = createStore(combineReducers({ user, leaderboard }), applyMiddleware(...middlewares));

const updateProfile = (uid) => {
  const updatePosition = async () => {
    const { position } = await fetch(`https://us-central1-quiz-database-13c42.cloudfunctions.net/get/position?id=8267218822&userId=${uid}`).then((response) => response.json());

    store.dispatch({ type: UPDATE_POSITION, payload: position });
  };

  firebase.database().ref(`users/${uid}`).on('value', (snapshot) => {
    const profile = snapshot.val();

    store.dispatch({ type: PROFILE_UPDATE, payload: { ...profile, id: uid } });
  });

  updatePosition();
  setInterval(updatePosition, 30000);
}

firebase.auth().onAuthStateChanged(async (user) => {
  if (user) {
    const profile = await firebase.database().ref(`users/${user.uid}`).once('value').then((snapshot) => snapshot.val());

    store.dispatch({ type: AUTHENTICATE_SUCCESS, payload: { ...profile, token: user.uid, id: user.uid } });

    updateProfile(user.uid);
  }
});

export const getProfileData = (state) => ({
  ...state.user.statistics,
  token: state.user.token,
  image: state.user.image,
  id: state.user.id,
  name: state.user.name,
  nick: state.user.nick,
  position: state.user.position,
});


export const logOut = () => ({ type: LOGOUT });

export const authenticate = (payload) => async (dispatch) => {
  dispatch({ type: AUTHENTICATE_START });
  await firebase.auth().setPersistence(firebase.auth.Auth.Persistence.SESSION);
  return firebase.auth().signInWithEmailAndPassword(payload.email, payload.password).catch(function(error) {
    dispatch({ type: AUTHENTICATE_ERROR, error: error.message });
  });
};

let interval;

export const updateStatus = () => (dispatch, getState) => {
  clearInterval(interval);
  const updateStatus = () => {
    const { id } = getProfileData(getState());
    if (!id) {
      return clearInterval(interval);
    }
    firebase.database().ref(`users/${id}`).update({
      lastupdate: new Date().getTime(),
    });
  };

  interval = setInterval(updateStatus, 30000);
  updateStatus();
};

export const getError = (state) => state.user.error;