import React from 'react';
import { createBrowserHistory } from 'history';
import { Router, Route, Switch, Redirect } from 'react-router';
import CodeWars from './CodeWars';
import Login from './Login';
import Profile from './Profile';
import Layout from './Layout';

const history = createBrowserHistory();

const App = () => (
  <Router history={history}>
    <Switch>
      <Route path="/leaderboard" component={CodeWars} />
      <Route path="/user/login" component={(props) => <Layout children={<Login {...props} />} />} />
      <Route path="/user" component={(props) => <Layout children={<Profile {...props} />} />} />
      <Redirect to="/user" />
    </Switch>
  </Router>
);

export default App;