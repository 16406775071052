import React from 'react';
import PropTypes from 'prop-types';
import './leaderboard.css';


const Leaderboard = ({ data, noWinnerYet }) => (
  <div className='leaderboard'>
    <div className="header">
      <div className="header-item col-position">#</div>
      <div className="header-item col-username">username</div>
      <div className="header-item col-languages">language(s)</div>
      <div className="header-item col-completed">done</div>
      <div className="header-item col-score">score</div>
    </div>

    <div className="competitors">
      {data.map((competitor, i) => (
        <div className='competitor' key={i}>
          <div className={'status ' + (competitor.online && 'online')} />
          <div className="col-position">{noWinnerYet ? i + 1 : i + 2}.</div>
          <div className="col-username">{competitor.nick}</div>
          <div className="col-languages">{!!competitor.statistics && competitor.statistics.languages && competitor.statistics.languages.join(', ')}</div>
          <div className="col-completed">{!!competitor.statistics && competitor.statistics.completed}</div>
          <div className="col-score">{!!competitor.statistics && competitor.statistics.score}</div>
        </div>
      ))}
    </div>
  </div>
)

Leaderboard.propTypes = {
  noWinnerYet: PropTypes.bool,
  data: PropTypes.array
};

export default Leaderboard;
