import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './score.css';

export default class Score extends Component {
  state = {
    score: this.props.score || 0,
  }

  componentDidUpdate(prevProps) {
    if (prevProps.score !== this.props.score) {
      this.animateScore(prevProps.score, this.props.score);
    }
  }

  animateScore(oldScore, newScore) {
    if (oldScore > newScore) {
      return this.setState({ score: newScore });
    }

    let value = oldScore;

    const increment = () => {
        if (value == newScore) {
          clearInterval(intervalId)
        }
        else {
          value++;
          this.setState({ score: value });
        };
    };

    const intervalId = setInterval(increment, 60);
  }

  render() {
    return (
      <div className='score'>
        <div className="title">Collective score:</div>

        <div className="value" style={{ color: `rgb(${255 - (this.state.score / 1001 * 255)}, 255, ${255 - (this.state.score / 1001 * 255)})`, textShadow: `0 0 150px rgba(0, 255, 0, ${1 / (1 / this.state.score * 1001)})` }}>{this.state.score}</div>
        
        {this.state.score <= 1001 ?
          <div className="left">{1001 - this.state.score} to go</div>
          :
          <div className="left">yaay! We have {this.state.score - 1001} more points than 1001</div>
        }
      </div>
    )
  }
}

Score.propTypes = {
  score: PropTypes.number,
};
