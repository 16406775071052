import React from 'react';
import Typography from '@material-ui/core/Typography';
import { connect } from 'react-redux';
import { getProfileData, updateStatus } from './redux';

class Profile extends React.Component {
  constructor(props) {
    super(props);
    if (!this.props.token) {
      this.props.history.replace('/user/login');
    }

    this.props.updateStatus();
  }

  render() {
    return (
      <>
        <Typography variant="h3" color="inherit" style={{ paddingBottom: 50 }}>
          Hi, {this.props.nick || this.props.name}!
        </Typography>
        <img src={this.props.image} style={{ paddingBottom: 50 }} />
        <Typography variant="body1" color="inherit" style={{ textAlign: 'center' }}>
          Your score is: {this.props.score}<br />
          Completed: {this.props.completed}<br />
          Position: {Number(this.props.position || 0) + 1}
        </Typography>
        
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  ...getProfileData(state),
});

const mapDispatchToProps = (dispatch) => ({
  updateStatus: () => dispatch(updateStatus()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Profile);