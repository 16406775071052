import { createReducer } from "redux-create-reducer";

const FETCH_LEADERBOARD_DATA = 'FETCH_LEADERBOARD_DATA';

export const leaderboard = createReducer({
  quiz : {},
  data : [
    {
      image: '',
      name: '',
      statistics: {
        score: 0,
        completed: 0,
        languages: [],
      }
    }
  ],
  winner: {
    statistics: {
      score: 0,
      completed: 0,
      languages: [],
    }
  },
  collectiveScore: 0,
}, {
  [FETCH_LEADERBOARD_DATA]: (state, { payload }) => ({
    ...state,
    ...payload,
  }),
});

const defaultStat = { score: 0, languages: [] };

export const fetchLeaderboardData = () => async (dispatch) => {
  const data = await fetch('https://us-central1-quiz-database-13c42.cloudfunctions.net/get/leaderboard?id=8267218822')
  .then((res) => res.json());

  const [winner, ...results] = data.users.sort((a, b) => (b.statistics || defaultStat).score - (a.statistics || defaultStat).score);
  
  if ((winner.statistics || defaultStat).score === 0) {
    results.unshift(winner);
  }
  
  const collectiveScore = data.users.reduce((acc, curr) => acc + (curr.statistics || defaultStat).score, 0);

  results.forEach(result => {
    if ((result.statistics || defaultStat).languages) {
      result.languages = (result.statistics || defaultStat).languages.map((language) => {
        if (language === 'javascript' && (result.statistics || defaultStat).languages.length > 2) return 'js';
        return language;
      });
    }
  });

  dispatch({
    type: FETCH_LEADERBOARD_DATA,
    payload: {
      quiz: data.quiz,
      data: results.slice(0, 7),
      winner,
      collectiveScore,
    },
  });
};