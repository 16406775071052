import React from 'react';
import PropTypes from 'prop-types';
import './timeRemaining.css';

var moment = require('moment');

const TimeRemaining = ({ startDate, endDate }) => (
  <div className='timeRemaining'>
    <div className="title">
      Competition ends
    </div>
    
    <div className="time">
      {moment(endDate).fromNow()}
    </div>
    
    <div className="left">
      Competition started {moment(startDate).fromNow()}
    </div>
  </div>
);

export default TimeRemaining;
