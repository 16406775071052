import React from 'react';
import PropTypes from 'prop-types';
import './currentWinner.css';

const CurrentWinner = ({ winner }) => (
  <div className='currentWinner'>
    <div className="promotion">
      <div className="top">
        <div className="title">1001 CODEWARS NIGHT</div>
        <div className="hosted-by">Hosted by POSSIBLE</div>
      </div>

      <div className="bottom">
        <div className="prize">Grand prize: Raspberry Pi 3</div>
      </div>

      <img src="prize.png" alt="Prize" className="prize-img"/>
    </div>

    {(winner.statistics && winner.statistics.score) ?
      <div className="winner">
        <img src={winner.image || 'winner_placeholder.jpg'} alt="winner" className="user-img"/>

        <div className="info">
          <div className="top">
            <div className="name">1. {winner.name || winner.user}</div>
            <div className="completed">{winner.statistics.completed} completed</div>
          </div>

          <div className="bottom">
            <div className="languages">
              [ {winner.statistics.languages && winner.statistics.languages.join(', ')} ]
            </div>
          </div>
        </div>

        <div className="score">
          {winner.statistics.score}
        </div>
      </div>
      :
      null
    }
  </div>
);

CurrentWinner.propTypes = {
  winner: PropTypes.object,
};

export default CurrentWinner;
